window.onload = function () {
  const form = document.querySelectorAll("form");

  for (let i = 0; i < form.length; i++) {
    form[i].onsubmit = load.bind(form[i]);
  }
};

function load(event) {
  const button = event.submitter;

  button.setAttribute("disabled", "disabled");

  button.classList.add("btn--load");

  button.innerHTML =
    '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
}
